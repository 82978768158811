.fib {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.fi {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.fi:before {
  content: '\00a0';
}
.fi.fis {
  width: 1em;
}
.fi-xx {
  background-image: url(/assets/flag-icons/flags/4x3/xx.svg);
}
.fi-xx.fis {
  background-image: url(/assets/flag-icons/flags/1x1/xx.svg);
}
.fi-gb-eng {
  background-image: url(/assets/flag-icons/flags/4x3/gb-eng.svg);
}
.fi-gb-eng.fis {
  background-image: url(/assets/flag-icons/flags/1x1/gb-eng.svg);
}
.fi-gb {
  background-image: url(/assets/flag-icons/flags/4x3/gb.svg);
}

.fi-de {
  background-image: url(/assets/flag-icons/flags/4x3/de.svg);
}
.fi-de.fis {
  background-image: url(/assets/flag-icons/flags/1x1/de.svg);
}

.fi-us {
  background-image: url(/assets/flag-icons/flags/4x3/us.svg);
}
.fi-us.fis {
  background-image: url(/assets/flag-icons/flags/1x1/us.svg);
}
